import React from 'react';
import PropTypes from 'prop-types';
import { ColumnBox, ColumnIcon, ColumnHeading, ColumnDescription, ColumnAnchor, IconImg } from './Column.styled';

const Column = ({ icon, iconLink, header, descriptionHTML, link, linkText, centerContent }) => {
  return (
    <ColumnBox className="col-sm">
      <ColumnIcon center>
        {!iconLink ? (
          <IconImg
            src={icon.url}
            alt="icon"
            style={{ maxWidth: '250px', maxHeight: '250px', width: 'auto', height: 'auto' }}
          />
        ) : (
          <a href={iconLink} target="_blank" rel="noopener noreferrer">
            <IconImg
              src={icon.url}
              alt="icon"
              style={{ maxWidth: '250px', maxHeight: '250px', width: 'auto', height: 'auto' }}
            />
          </a>
        )}
      </ColumnIcon>
      <ColumnHeading center>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: header }}
        />
      </ColumnHeading>
      <ColumnDescription>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: descriptionHTML }}
        />
      </ColumnDescription>
      {!!link && <ColumnAnchor href={link}>{linkText}</ColumnAnchor>}
    </ColumnBox>
  );
};

Column.propTypes = {
  icon: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  iconLink: PropTypes.string,
  header: PropTypes.string,
  descriptionHTML: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  centerContent: PropTypes.bool,
};

export default Column;
