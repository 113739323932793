import styled from 'styled-components';

import { COLORS } from '../../constants.styles';

export const ColumnBox = styled.div`
  margin-right: 10px;
`;

export const ColumnIcon = styled.div`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'start')};
  margin-bottom: 20px;
  min-height: 70px;
`;

export const IconImg = styled.img`
  margin: 0;
`;

export const ColumnHeading = styled.div`
  text-align: ${props => (props.center ? 'center' : 'start')};
  color: black;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
`;

export const ColumnDescription = styled.div`
  color: ${COLORS.DARK_GREY};
  text-align: justify;
`;

export const ColumnAnchor = styled.a`
  color: #51a5ff;
`;
