/* eslint-disable camelcase */
import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';

import Page from '../layout/Page';
import Header from '../components/common/Header';
import Subheader from '../components/common/Subheader';
import Paragraph from '../components/common/Paragraph';
import Section from '../components/common/Section';

import { Column } from '../components/Column';
import { ColumnWrapper } from '../page-styles/etudes-marches.styled';
import { COLORS, COLUMN_ICON_SIZE } from '../constants.styles';

export default ({ data }) => {
  const featureColumns = get(data, 'prismicEnterprisePage.data.features.document.data');

  return (
    <Page>
      <Section theme={COLORS.WHITE}>
        <div className="container">
          <Header>{get(data, 'prismicEnterprisePage.data.header.text')}</Header>
          <Subheader>{get(data, 'prismicEnterprisePage.data.subheader.text')}</Subheader>
          <Paragraph>{get(data, 'prismicEnterprisePage.data.description.text')}</Paragraph>
        </div>
      </Section>
      <Section theme={COLORS.LIGHT_GREY}>
        <Subheader>{get(data, 'prismicEnterprisePage.data.features_header.text')}</Subheader>
        <div className="container">
          <ColumnWrapper className="row">
            {get(featureColumns, 'body', []).map((column, index) => {
              const { icon, header, description, tail_link, link_text } = column.primary;
              return (
                <Column
                  key={index}
                  icon={{
                    url: icon.url,
                    width: COLUMN_ICON_SIZE,
                    height: COLUMN_ICON_SIZE,
                  }}
                  header={header.text}
                  descriptionHTML={description.html}
                  link={tail_link}
                  linkText={link_text}
                />
              );
            })}
          </ColumnWrapper>
        </div>
      </Section>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicEnterprisePage {
      data {
        header {
          text
        }
        subheader {
          text
        }
        description {
          text
        }
        features_header {
          text
        }
        features {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicColumns {
                data {
                  body {
                    ... on PrismicColumnsBodyColumn {
                      primary {
                        icon {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                        header {
                          text
                        }
                        description {
                          html
                        }
                        tail_link
                        link_text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
