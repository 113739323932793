import styled from 'styled-components';

import { COLORS } from '../../constants.styles';

export const ParagraphWrapper = styled.div`
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledParagraph = styled.div`
  max-width: 900px;
  color: ${COLORS.DARK_GREY};
  text-align: justify;
  text-align-last: center;
`;
