import React from 'react';
import { ParagraphWrapper, StyledParagraph } from './Paragraph.styled';

export default props => (
  <ParagraphWrapper>
    <StyledParagraph>
      <div
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: props.children }}
      />
    </StyledParagraph>
  </ParagraphWrapper>
);
